import { Link } from 'react-router-dom';
import { cursorPointer, updateCursor } from 'utils';
import CTAPlanet from 'images/ctaPlanet.svg';
import Styles from './ctaSection.module.scss';

function CtaSection({ planetImage }) {
	return (
		<div className={Styles.mainContainer} onMouseEnter={() => updateCursor('white')}>
			<div className={Styles.imageContainer}>
				<img src={planetImage || CTAPlanet} className={Styles.planetImage} alt="cta planet" />
			</div>
			<div className={`container ${Styles.contentContainer}`}>
				<h3 className={Styles.ctaHeading}>
					Creating experiences light-years ahead of the competition
				</h3>
				<Link
					to='/contact'
					className={`mt-4 ${Styles.buildSomethingButton}`}
					onMouseEnter={() => {
						updateCursor('blue');
						cursorPointer(true);
					}}
					onMouseLeave={() => {
						updateCursor('white');
						cursorPointer(false);
					}}>
						Let's Build Something
				</Link>
			</div>
		</div>
	);
}

export { CtaSection };
