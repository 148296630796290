import React from 'react';
import Parallax from 'parallax-js';
import { TimelineMax } from 'gsap';
import { updateCursor } from 'utils';
import shiningStarImage from 'images/shiningStar.svg';
import shiningStarPulseImage from 'images/shiningStarPulse.svg';
import Styles from './parallaxStarsBackground.module.scss';

class ParallaxStarsBackground extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isHeader: props.isHeader,
		};
	}

	componentDidMount() {
		const shiningStar = document.getElementById('mainLandingShiningStar');
		const shiningStarPulse = document.getElementById('mainLandingShiningStarPulse');
		const shootingStar = document.getElementById('mainLandingShootingStar');
		const shiningStarAnimation = new TimelineMax();
		const shiningStarPulseAnimation = new TimelineMax();
		const shootingStarAnimation = new TimelineMax();
		const parallaxScene = document.getElementById('parallaxScene');
		const parallaxInstance = new Parallax(parallaxScene);

		if (this.state.isHeader === 'SMALL') {
			parallaxInstance.friction(0.002, 0.05);
		} else if (this.state.isHeader === 'MEDIUM') {
			parallaxInstance.friction(0.002, 0.02);
		} else {
			parallaxInstance.friction(0.002, 0.002);
		}
		shiningStarAnimation.from(shiningStar, 0, { opacity: '0' })
			.to(shiningStar, 9, { opacity: '1' });

		shiningStarPulseAnimation.from(shiningStarPulse, 0, { scale: 1, opacity: 1 })
			.to(shiningStarPulse, 1, { scale: 20, opacity: 0 })
			.to(shiningStarPulse, 10, {
				scale: 20,
				opacity: 0,
				onComplete() {
					shiningStarPulseAnimation.restart();
				}
			});

		shootingStarAnimation.to(shootingStar, 10, {
			onComplete() {
				shootingStar.style.top = `${Math.floor(Math.random() * Math.floor(window.innerHeight))}px`;
				shootingStar.style.left = `${Math.floor(Math.random() * Math.floor(window.innerWidth))}px`;
				shootingStarAnimation.restart();
			}
		});
	}

	render() {
		const { isHeader } = this.state;
		let starsClass;
		let mainClass;

		if (isHeader === 'SMALL') {
			starsClass = Styles.starsSmallHeader;
			mainClass = Styles.headerContainer;
		} else if (isHeader === 'MEDIUM') {
			starsClass = Styles.starsMediumHeader;
			mainClass = Styles.headerContainer;
		} else {
			starsClass = Styles.starsBackground;
			mainClass = Styles.mainContainerBackground;
		}

		return (
			<div>
				<div
					id="parallaxStarsBackground"
					className={`${Styles.parent} ${mainClass}`}
					onMouseEnter={() => updateCursor('white')}>
					<div id="parallaxScene">
						<div className={`${Styles.parent} ${starsClass}`} data-depth="-0.3">
							<img id="mainLandingShiningStar" className={Styles.shiningStar} src={shiningStarImage} alt="star" />
							<img id="mainLandingShiningStarPulse" className={Styles.shiningStar} src={shiningStarPulseImage} alt="star pulse" />
							<div id="mainLandingShootingStar" className={Styles.shootingStar}></div>
						</div>
						{this.props.componentToRender}
					</div>
				</div>
			</div>
		);
	}
}

export { ParallaxStarsBackground };
