import React from 'react';
import Lottie from 'lottie-web';
import 'bootstrap/js/dist/carousel';
import { CaseStudyHeroSection } from 'components/caseStudyHeroSection';
import {
	BroaderAppeal,
	Collage,
	FirstCarouselAnimation,
	PlanetTelis,
	RecruitmentBackground,
	SecondCarouselAnimation,
	ThirdCarouselAnimation,
	TestTubesOvalBackground,
	WebDevelopment
} from 'images/telis';
import { TwoColumnGrid } from 'layouts/twoColumnGrid/twoColumnGrid';
import {
	FloatingBubblesLottie,
	RecruitmentLottie,
	TestTubesLottie
} from 'lotties/telis';
import { cursorPointer, updateCursor } from 'utils';
import { CtaSection } from '../components/ctaSection';
import SectionsData from '../sectionsData/telis.json';
import Styles from './telis.module.scss';

class TelisScreen extends React.Component {
	componentDidMount() {
		this.loadLottieAnimation('#testTubesLottieContainer', TestTubesLottie);
		this.loadLottieAnimation('#floatingBubblesLottieContainer', FloatingBubblesLottie);
		this.loadLottieAnimation('#recruitmentLottieContainer', RecruitmentLottie);
	}

	loadLottieAnimation = (container, animationData) => {
		Lottie.loadAnimation({
			container: document.querySelector(container),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData
		});
	};

	render() {
		const {
			heroSection,
			recruitment,
			broaderAppeal,
			testTubes,
			carouselSection,
			webDevelopment
		} = SectionsData;

		return (
			<main className={Styles.mainContainer}>
				<CaseStudyHeroSection
					starsBackgroundHeader={heroSection.starsBackgroundHeader}
					caseStudyHeading={heroSection.caseStudyHeading}
					link={heroSection.link}
					sloganHeading={heroSection.sloganHeading}
					caseStudyText={heroSection.caseStudyText} />
				<TwoColumnGrid
					gridStyles={Styles.recruitmentGridContainer}
					leftColumn={
						<div className={Styles.recruitmentLottieColumn} onMouseEnter={() => updateCursor('blue')}>
							<img
								src={RecruitmentBackground}
								alt="Recruitment Section Background"
								className={Styles.recruitmentBackground}
								width="100%"
								loading="lazy" />
							<div id="recruitmentLottieContainer" className={Styles.recruitmentLottie} />
						</div>
					}
					rightColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{recruitment.title}</h4>
							<p className="mt-3">
								{recruitment.paragraphOne}
								<br /><br />
								{recruitment.paragraphTwo}
							</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={Styles.broaderAppealGridContainer}
					isBackgroundWhite={true}
					revertOnMobile={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{broaderAppeal.title}</h4>
							<p className="mt-3">
								{broaderAppeal.paragraphOne}
								<br /><br />
								{broaderAppeal.paragraphTwo}
							</p>
						</article>
					}
					rightColumn={
						<img
							src={BroaderAppeal}
							alt="Balancing Technical Information for Broader Appeal"
							onMouseEnter={() => updateCursor('white')}
							width="100%"
							loading="lazy" />
					} />
				<TwoColumnGrid
					gridStyles={Styles.testTubesGridContainer}
					leftColumn={
						<div className={Styles.testTubesLottieColumn}>
							<div id="testTubesLottieContainer" className={Styles.testTubesLottie} onMouseEnter={() => updateCursor('blue')} />
							<img
								src={TestTubesOvalBackground}
								className={Styles.testTubesOvalBackground}
								alt="Magic potions"
								width="80%"
								loading="lazy" />
							<div
								id="floatingBubblesLottieContainer"
								className={Styles.floatingBubblesLottie}
								onMouseEnter={() => updateCursor('blue')}
								onMouseLeave={() => updateCursor('white')} />
						</div>
					}
					rightColumn={
						<article className={Styles.textSection} onMouseEnter={() => updateCursor('white')}>
							<h4 className="font-weight-bold">{testTubes.title}</h4>
							<p className="mt-3">
								{testTubes.paragraphOne}
								<br /><br />
								{testTubes.paragraphTwo}
							</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={Styles.carouselGridContainer}
					isBackgroundWhite={true}
					revertOnMobile={true}
					leftColumn={
						<article className={`${Styles.textSection}`}>
							<h4 className="font-weight-bold">{carouselSection.title}</h4>
							<p className="mt-3">
								{carouselSection.paragraphOne}
								<br /><br />
								{carouselSection.paragraphTwo}
							</p>
						</article>
					}
					rightColumn={
						<div
							id="carouselIndicators"
							className={`${Styles.carouselContainer} carousel carousel-dark slide carousel-fade`}
							data-bs-ride="carousel">
							<div className={`${Styles.carousel} carousel-indicators`}>
								<button
									type="button"
									data-bs-target="#carouselIndicators"
									data-bs-slide-to="0" className="active"
									aria-current="true"
									aria-label="Carousel 1"
									onMouseEnter={() => cursorPointer(true)}
									onMouseLeave={() => cursorPointer(false)} />
								<button
									type="button"
									data-bs-target="#carouselIndicators"
									data-bs-slide-to="1"
									aria-label="Carousel 2"
									onMouseEnter={() => cursorPointer(true)}
									onMouseLeave={() => cursorPointer(false)} />
								<button
									type="button"
									data-bs-target="#carouselIndicators"
									data-bs-slide-to="2"
									aria-label="Carousel 3"
									onMouseEnter={() => cursorPointer(true)}
									onMouseLeave={() => cursorPointer(false)} />
							</div>
							<div className="carousel-inner">
								<div className={`${Styles.carouselItemContainer} carousel-item active`} data-bs-interval="4000">
									<span className={Styles.carouselItemTitle}>Synthesis</span>
									<img src={FirstCarouselAnimation} className={Styles.firstCarouselAnimation} alt="First carousel" />
								</div>
								<div className={`${Styles.carouselItemContainer} carousel-item`} data-bs-interval="4000">
									<span className={Styles.carouselItemTitle}>Measurement</span>
									<img src={SecondCarouselAnimation} className={Styles.secondCarouselAnimation} alt="Second carousel" />
								</div>
								<div className={`${Styles.carouselItemContainer} carousel-item`} data-bs-interval="4000">
									<span className={Styles.carouselItemTitle}>Understanding</span>
									<img src={ThirdCarouselAnimation} className={Styles.thirdCarouselAnimation} alt="Third carousel" />
								</div>
							</div>
							<button
								className={`${Styles.carouselPreviousControl} carousel-control-prev`}
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide="prev">
								<span
									className="carousel-control-prev-icon"
									aria-hidden="true"
									onMouseEnter={() => cursorPointer(true)}
									onMouseLeave={() => cursorPointer(false)} />
								<span className="visually-hidden">Previous</span>
							</button>
							<button
								className={`${Styles.carouselNextControl} carousel-control-next`}
								type="button"
								data-bs-target="#carouselIndicators"
								data-bs-slide="next">
								<span
									className="carousel-control-next-icon"
									aria-hidden="true"
									onMouseEnter={() => cursorPointer(true)}
									onMouseLeave={() => cursorPointer(false)} />
								<span className="visually-hidden">Next</span>
							</button>
						</div>
					} />
				<TwoColumnGrid
					gridStyles={Styles.webDevelopmentGridContainer}
					leftColumn={
						<img
							src={WebDevelopment}
							alt="Web Development"
							width="100%"
							loading="lazy" />
					}
					rightColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{webDevelopment.title}</h4>
							<p className="mt-3">
								{webDevelopment.paragraphOne}
								<br /><br />
								{webDevelopment.paragraphTwo}
							</p>
						</article>
					} />
				<img
					src={Collage}
					alt="Collage"
					className={Styles.collage}
					width="100%"
					loading="lazy"
					onMouseEnter={() => updateCursor('blue')} />
				<CtaSection planetImage={PlanetTelis} />
			</main>
		);
	}
}

export { TelisScreen };
