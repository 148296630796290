import React from 'react';
import { updateCursor } from 'utils';
import Trees from 'images/trees.png';
import Styles from './treeLineSection.module.scss';

class TreeLineSection extends React.Component {
	render() {
		return (
			<div id="treeLineSection" onMouseEnter={() => updateCursor('white')}>
				<img src={Trees} alt="trees" className={Styles.trees} />
				<div className={Styles.treeSectionContent}>
					<div className="row"></div>
				</div>
			</div>
		);
	}
}

export { TreeLineSection };
