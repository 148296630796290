import React from 'react';
import { Link } from 'react-router-dom';
import { ParallaxStarsBackground } from 'components/parallaxStarsBackground';
import { updateCursor } from 'utils';
import Error404Illustration from 'images/404_illustration.svg';
import Styles from './errorScreen.module.scss';

class ErrorScreen extends React.Component {
	render() {
		return (
			<div id="errorScreen" className={Styles.mainContainer}>
				<ParallaxStarsBackground
					componentToRender={
						<div>
							<div data-depth="0.2" className={Styles.errorScreenContainer}>
								<div className={Styles.errorMessageContainer}>
									<h2>Looks like that space walk didn't quite go as planned...</h2>
									<img src={Error404Illustration} className={Styles.errorIllustration} alt="Error 404" />
									<h2>Page not found</h2>
									<Link className={Styles.backHomeButton} to='/' onMouseEnter={() => updateCursor('blue')} onMouseLeave={() => updateCursor('white')}>Back to Home Base</Link>
								</div>
							</div>
						</div>
					} />
			</div>
		);
	}
}

export { ErrorScreen };
