import React from 'react';
import { Link } from 'react-router-dom';
import { cursorPointer, updateCursor } from 'utils';
import Styles from './contactUsSection.module.scss';

class ContactUsSection extends React.Component {
	render() {
		return (
			<div className={Styles.contactUsSection} onMouseEnter={() => updateCursor('blue')}>
				<div className={Styles.mainContent}>
					<h3 className={`contactUsSectionHeading mx-3 ${Styles.heading}`}>Let's work together on your strategy, design, and technological needs</h3>
					<Link
						to={'/contact'}
						onClick={() => cursorPointer(false)}
						className={`contactUsSectionButton ${Styles.contactUsBtn}`}
						onMouseOver={() => cursorPointer(true)}
						onMouseOut={() => cursorPointer(false)}>
							Get in Touch
					</Link>
				</div>
			</div>
		);
	}
}

export { ContactUsSection };
