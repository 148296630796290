import React from 'react';
import Lottie from 'lottie-web';
import { CaseStudyHeroSection } from 'components/caseStudyHeroSection';
import {
	BrandingStrategy,
	Collage,
	ColorPsychology,
	EthicalUxBackground,
	Monorepo,
	PlanetGroupLoop,
	SharingInterests
} from 'images/groupLoop';
import { TwoColumnGrid } from 'layouts/twoColumnGrid/twoColumnGrid';
import {
	EthicalUxLottie,
	IllustrationLottie
} from 'lotties/groupLoop';
import { updateCursor } from 'utils';
import { CtaSection } from '../components/ctaSection';
import SectionsData from '../sectionsData/groupLoop.json';
import Styles from './groupLoop.module.scss';

class GroupLoopScreen extends React.Component {
	componentDidMount() {
		this.loadLottieAnimation('#illustrationLottieContainer', IllustrationLottie);
		this.loadLottieAnimation('#ethicalUxLottieContainer', EthicalUxLottie);
	}

	loadLottieAnimation = (container, animationData) => {
		Lottie.loadAnimation({
			container: document.querySelector(container),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData
		});
	};

	render() {
		const {
			heroSection,
			commonInterests,
			branding,
			colorPsychology,
			illustrations,
			userPrivacy,
			monorepo
		} = SectionsData;

		return (
			<main className={Styles.mainContainer}>
				<CaseStudyHeroSection
					starsBackgroundHeader={heroSection.starsBackgroundHeader}
					caseStudyHeading={heroSection.caseStudyHeading}
					link={heroSection.link}
					sloganHeading={heroSection.sloganHeading}
					caseStudyText={heroSection.caseStudyText} />
				<TwoColumnGrid
					gridStyles={Styles.commonInterestsGridContainer}
					isBackgroundWhite={true}
					leftColumn={
						<article className={`${Styles.textSection} py-lg-4`}>
							<h4 className="font-weight-bold">{commonInterests.title}</h4>
							<p className="mt-3">{commonInterests.paragraphOne}</p>
						</article>
					}
					rightColumn={
						<img
							src={SharingInterests}
							alt="Sharing Common Interests"
							width="100%"
							height="100%"
							loading="lazy" />
					}
				/>
				<TwoColumnGrid
					gridStyles={Styles.brandingGridContainer}
					revertOnMobile={true}
					leftColumn={
						<img
							src={BrandingStrategy}
							alt="Branding Strategy And Logo Design"
							width="100%"
							loading="lazy" />
					}
					rightColumn={
						<article
							className={Styles.textSection}>
							<h4 className="font-weight-bold">{branding.title}</h4>
							<p className="mt-3">
								{branding.paragraphOne}
								<br /><br />
								{branding.paragraphTwo}
							</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={Styles.colorPsyhcologyGridContainer}
					isBackgroundWhite={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{colorPsychology.title}</h4>
							<p className="mt-3">
								{colorPsychology.paragraphOne}
								<br /><br />
								{colorPsychology.paragraphTwo}
							</p>
						</article>
					}
					rightColumn={
						<img
							src={ColorPsychology}
							alt="Color Psychology"
							width="100%"
							loading="lazy" />
					} />
				<TwoColumnGrid
					gridStyles={Styles.illustrationGridContainer}
					revertOnMobile={true}
					leftColumn={
						<div
							className={Styles.illustrationLottieColumn}
							onMouseEnter={() => updateCursor('blue')}
							onMouseLeave={() => updateCursor('white')}>
							<div id="illustrationLottieContainer" />
						</div>
					}
					rightColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold mt-lg-4">{illustrations.title}</h4>
							<p className="mt-3">
								{illustrations.paragraphOne}
								<br /><br />
								{illustrations.paragraphTwo}
							</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={Styles.ethicalUxGridContainer}
					isBackgroundWhite={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{userPrivacy.title}</h4>
							<p className="mt-3">{userPrivacy.paragraphOne}</p>
						</article>
					}
					rightColumn={
						<div className={Styles.ethicalUxLottieColumn}>
							<div
								id="ethicalUxLottieContainer"
								className={Styles.ethicalUxLottie}
								onMouseEnter={() => updateCursor('blue')} />
							<img
								src={EthicalUxBackground}
								className={Styles.ethicalUxBackground}
								alt="Ethical UX And User Privacy"
								width="95%"
								loading="lazy" />
						</div>
					} />
				<TwoColumnGrid
					gridStyles={Styles.monorepoGridContainer}
					revertOnMobile={true}
					leftColumn={
						<img
							src={Monorepo}
							alt="Monorepo"
							onMouseEnter={() => updateCursor('white')}
							width="100%"
							loading="lazy" />
					}
					rightColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{monorepo.title}</h4>
							<p className="mt-3">
								{monorepo.paragraphOne}
								<br /><br />
								{monorepo.paragraphTwo}
							</p>
						</article>
					} />
				<div onMouseEnter={() => updateCursor('white')} className={Styles.collageContainer}>
					<img
						src={Collage}
						alt="Collage"
						className={Styles.collage}
						width="100%"
						loading="lazy"
						onMouseEnter={() => updateCursor('blue')}
						onMouseLeave={() => updateCursor('white')} />
				</div>
				<CtaSection planetImage={PlanetGroupLoop} />
			</main>
		);
	}
}

export { GroupLoopScreen };
