import React from 'react';
import { GreyLines } from 'images/zeta';
import Styles from './colorPalette.module.scss';

class ColorPalette extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundColor: null,
			logo: props.primaryLogo,
		};
	}

	colorPaletteHover(colorPaletteBackground, changeLogoTo) {
		let logo;

		if (changeLogoTo === 'white') {
			logo = this.props.whiteLogo;
		} else if (changeLogoTo === 'black') {
			logo = this.props.blackLogo;
		} else {
			logo = this.props.primaryLogo;
		}

		this.setState({
			backgroundColor: colorPaletteBackground,
			logo
		});
	}

	render() {
		const { colorPalette, gradients } = this.props;

		return (
			<div className={Styles.colorPaletteContainer}>
				<div
					className={Styles.tabsContainer}
					id="#colorPaletteCard"
					style={{
						background: this.state.backgroundColor === null ? `url(${GreyLines})` : this.state.backgroundColor,
						backgroundRepeat: this.state.backgroundColor === null ? 'no-repeat' : null,
						backgroundSize: this.state.backgroundColor === null ? '100% 100%' : null,
						width: '100%',
						height: '100%',
						transition: 'all 0.3s ease-in-out',
					}}>
					<img src={this.state.logo} alt="Zeta surgical logo" width="20%" />
					<div className={Styles.fontsTab}>
						<h5>Fonts</h5>
					</div>
					<div className={Styles.gradientsTab}>
						<h5>Gradients</h5>
						<div className={Styles.tabLayout}>
							{gradients.map((gradient, index) => (
								<div
									key={index}
									className={Styles.gradientColors}
									style={{
										transition: 'background 0.3s',
										background: gradient.background
									}}
									onMouseOver={() => {
										this.colorPaletteHover(gradient.background, gradient.changeLogoTo);
									}} />
							))}
						</div>
					</div>
					<div className={Styles.colorPaletteTab}>
						<h5>Color Palette</h5>
						<div className={Styles.tabLayout}>
							<div
								style={{
									background: `url(${GreyLines})`,
									backgroundSize: '450% 450%',
									border: '2px solid #f5f5f5'
								}}
								className={Styles.paletteColors}
								onMouseOver={() => this.colorPaletteHover(null)} />
							{Object.keys(colorPalette).map((key, index) => (
								<div
									key={index}
									className={Styles.paletteColors}
									style={{ backgroundColor: colorPalette[key] }}
									onMouseOver={() => {
										let logo = 'black';

										if (colorPalette[key] === '#282828') {
											logo = 'blue';
										} else if (colorPalette[key] === '#633cbb' || colorPalette[key] === '#e95200') {
											logo = 'white';
										}

										this.colorPaletteHover(colorPalette[key], logo);
									}} />
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export { ColorPalette };
