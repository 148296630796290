import React from 'react';
import Lottie from 'lottie-web';
import { Col, Row } from 'react-bootstrap';
import { updateCursor } from 'utils/index';
import DnaLottie from 'lotties/dna-purple.json';
import { CaseStudyHeroSection } from 'components/caseStudyHeroSection';
import {
	AlveaLogo,
	Collage,
	Reliability,
	Trust
} from 'images/alvea';
import PlanetAlvea from 'images/alvea/planetAlveaCtaSection.svg';
import { CtaSection } from '../components/ctaSection';
import Styles from './alvea.module.scss';

class AlveaScreen extends React.Component {
	componentDidMount() {
		Lottie.loadAnimation({
			container: document.querySelector('#dnaLottieContainer'),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: DnaLottie
		});
	}

	render() {
		return (
			<main className={Styles.mainContainer}>
				<CaseStudyHeroSection
					starsBackgroundHeader= 'MEDIUM'
					caseStudyHeading= 'Alvea Case Study'
					sloganHeading= 'Doing our bit to help vaccinate the world'
					caseStudyText= {'The COVID-19 pandemic shook the world in ways that no one imaged. It brought about a public health effort like no other, leading to the rise of companies looking to contribute to the social good of better public health. Alvea is one of our MedTech partners whom we dedicated immense resources to so that we too could do our part. Our partnership with Alvea, at a time when misinformation and skepticism was rampant about public health treatments, served as an amazing opporunity for our team.\n\nThe scope of this project included branding (brand colors, themes, logo, etc.) and website creation/positioning.'}
				/>
				<Row onMouseEnter={() => updateCursor('white')} className="flex-column-reverse flex-lg-row">
					<Col sm={12} lg={6} className={Styles.blueContainer}>
						<div className={`${Styles.contentContainer} d-flex flex-column justify-content-center`}>
							<h4 className="font-weight-bold mb-3">The Challenge of Invoking Trust</h4>
							<p className="m-0">
								In order to be effective in its mission, invoking trust
								was a mandatory part of the Alvea mission. With their goal
								of making vaccines more accessible, if the public did not trust
								them then they would fall flat.
								<br/><br/>
								Trust as a central part of our design process was the focal point
								around which we focused everything we presented to the Alvea team.
							</p>
						</div>
					</Col>
					<Col sm={12} lg={6} className="m-0 p-0">
						<img src={Trust} alt="Invoking Trust" width="100%" height="100%" />
					</Col>
				</Row>
				<Row onMouseEnter={() => updateCursor('white')}>
					<Col sm={12} lg={6} className="m-0 p-0">
						<img src={Reliability} alt="Reliability" width="100%" height="100%" />
					</Col>
					<Col sm={12} lg={6} className={Styles.blueContainer}>
						<div className={`${Styles.contentContainer} d-flex flex-column justify-content-center`}>
							<h4 className="font-weight-bold mb-3">Offering the perception of reliability</h4>
							<p className="m-0">
								Given the state of the worldwide pockets of disbelief in the
								messaging that governments were putting out about vaccines and their
								side effects, ensuring that Alvea's vaccines were also perceived as reliable
								and safe was the second focal point around which we developed our process.
							</p>
						</div>
					</Col>
				</Row>
				<h4 onMouseEnter={() => updateCursor('white')} className={Styles.toughChallenges}>
					The toughest of challenges lead to the best of solutions
				</h4>
				<Row onMouseEnter={() => updateCursor('blue')}>
					<Col sm={12} lg={6} className="m-0 p-0">
						<img src={AlveaLogo} alt="Alvea Logo" width="100%" height="100%" />
					</Col>
					<Col sm={12} lg={6} className={Styles.whiteContainer}>
						<div className={`${Styles.contentContainer} d-flex flex-column justify-content-center`}>
							<h4 className="font-weight-bold mb-3">Logo</h4>
							<p className="m-0">
								Given this trust and reliability focus, we tested many iterations
								of the Alvea logo. Focusing the brand's primary color on blue let us
								try to give a sense of tranquility and security, both emotions that we
								associate with being trustworthy and reliable.
								<br/><br/>
								Ultimately we landed on a version that showed how they worked with bonding
								molecules/played off of the double helix in DNA.
							</p>
						</div>
					</Col>
				</Row>
				<Row onMouseEnter={() => updateCursor('blue')} className="flex-column-reverse flex-lg-row">
					<Col sm={12} lg={6} className={Styles.whiteContainer}>
						<div className={`${Styles.contentContainer} d-flex flex-column justify-content-center`}>
							<h4 className="font-weight-bold mb-3">Animation</h4>
							<p className="m-0">
								We always look to add an extra dimension to the pages we make that,
								engage users in unique ways and keep them wanting more.
								<br/><br/>
								The custom double helix animation that we created for Alvea serves that
								purpose exactly. Placed in the hero section of the website, it is meant to
								not only immediately draw people in to the site but also give them a sense
								of the advanced medical technology that Alvea works with.
							</p>
						</div>
					</Col>
					<Col sm={12} lg={6} className={`m-0 p-0 ${Styles.dnaLottieContainer}`}>
						<div id="dnaLottieContainer" className={Styles.dnaLottie}></div>
					</Col>
				</Row>
				<Row onMouseEnter={() => updateCursor('white')} className="flex-column-reverse flex-lg-row">
					<Col sm={12} lg={6} className={Styles.blueContainer}>
						<div className={`${Styles.contentContainer} d-flex flex-column justify-content-center`}>
							<h4 className="font-weight-bold mb-3">Website Design & Development</h4>
							<p className="m-0">
								Our approach to putting together a cohesive brand image is always about
								deeply understanding a business and its core mission.
								<br/><br/>
								After we developed a clear understanding of the long and short term strategic
								goals for Alvea, we took all of our learnings to create a scalable design system
								for the Alvea brand. Those branding and image guidelines ultimately directed how we
								went about our creative process.
								<br/><br/>
								A balance between being informational without being too text heavy resulted
								in a site that serves up important information in a graphical and interesting way.
							</p>
						</div>
					</Col>
					<Col sm={12} lg={6} className="m-0 p-0">
						<img src={Collage} alt="Website Development" width="100%" height="100%" />
					</Col>
				</Row>
				<CtaSection planetImage={PlanetAlvea} />
			</main>
		);
	}
}

export { AlveaScreen };
