import { Component, createRef } from 'react';
import 'bootstrap/js/dist/carousel';
import { cursorPointer } from 'utils/index';
import Styles from './imageSwiper.module.scss';

class ImageSwiper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isDragging: false,
			showPrevButton: false,
			showNextButton: true,
		};

		this.containerRef = createRef();

		this.startX = 0;
	}

	updateButtonVisibility() {
		const container = this.containerRef.current;
		const maxScrollLeft = container.scrollWidth - container.clientWidth;

		this.setState({
			showPrevButton: container.scrollLeft > 0,
			showNextButton: container.scrollLeft < maxScrollLeft,
		});
	}

	handleSmoothScroll(targetScrollLeft) {
		const container = this.containerRef.current;
		const startScrollLeft = container.scrollLeft;
		const distance = targetScrollLeft - startScrollLeft;
		const duration = 300;
		const startTime = performance.now();

		const animateScroll = (currentTime) => {
			const elapsedTime = currentTime - startTime;
			const progress = Math.min(elapsedTime / duration, 1);
			const ease = 0.5 * (1 - Math.cos(Math.PI * progress));

			container.scrollLeft = startScrollLeft + distance * ease;

			if (progress < 1) {
				window.requestAnimationFrame(animateScroll);
			} else {
				this.updateButtonVisibility();
			}
		};

		window.requestAnimationFrame(animateScroll);
	}

	handleMouseDown(e) {
		e.preventDefault();

		this.setState({ isDragging: true });

		this.startX = e.clientX;
	}

	handleMouseMove(e) {
		if (this.state.isDragging) {
			const container = this.containerRef.current;
			const deltaX = this.startX - e.clientX;

			container.scrollLeft += deltaX;
			this.startX = e.clientX;

			this.updateButtonVisibility();
		}
	}

	handleMouseUp() {
		this.setState({ isDragging: false });
	}

	handleSlide(direction) {
		const container = this.containerRef.current;

		if (direction === 'left') {
			this.handleSmoothScroll(container.scrollLeft - container.clientWidth / 4);
		}

		if (direction === 'right') {
			this.handleSmoothScroll(container.scrollLeft + container.clientWidth / 4);
		}
	}

	componentDidMount() {
		const container = this.containerRef.current;

		container.addEventListener('mousemove', (e) => this.handleMouseMove(e));
		container.addEventListener('mouseup', (e) => this.handleMouseUp(e));
		container.addEventListener('scroll', () => this.updateButtonVisibility());
	}

	componentWillUnmount() {
		const container = this.containerRef.current;

		container.removeEventListener('mousemove', this.handleMouseMove);
		container.removeEventListener('mouseup', this.handleMouseUp);
		container.removeEventListener('scroll', () => this.updateButtonVisibility());
	}

	render() {
		const { src, alt } = this.props;
		const { showPrevButton, showNextButton } = this.state;

		return (
			<div className={Styles.swiperWrapper}>
				{showPrevButton
					&& <button
						className={`${Styles.arrowButtonLeft} carousel-control-prev`}
						onClick={() => this.handleSlide('left')}>
						<span
							className="carousel-control-prev-icon"
							aria-hidden="true"
							onMouseEnter={() => cursorPointer(true)}
							onMouseLeave={() => cursorPointer(false)} />
						<span className="visually-hidden">Previous</span>
					</button>
				}
				<div
					className={Styles.swiperContainer}
					ref={this.containerRef}
					onMouseDown={(e) => this.handleMouseDown(e)}
					onMouseLeave={() => this.setState({ isDragging: false })}
				>
					<img src={src} alt={alt} />
				</div>
				{showNextButton
					&& <button
						className={`${Styles.arrowButtonRight} carousel-control-next`}
						onClick={() => this.handleSlide('right')}>
						<span
							className="carousel-control-next-icon"
							aria-hidden="true"
							onMouseEnter={() => cursorPointer(true)}
							onMouseLeave={() => cursorPointer(false)} />
						<span className="visually-hidden">Previous</span>
					</button>
				}
			</div>
		);
	}
}

export { ImageSwiper };
