import React from 'react';
import { Link } from 'react-router-dom';
import { ParallaxStarsBackground } from 'components/parallaxStarsBackground';
import { cursorPointer, updateCursor } from 'utils';
import Styles from './caseStudyHeroSection.module.scss';

class CaseStudyHeroSection extends React.Component {
	renderWithLineBreaks(text) {
		const paragraphs = text.split('\n');

		return (
			<>
				{paragraphs.map((paragraph, index) => (
					<React.Fragment key={index}>
						{paragraph}
						{index < paragraphs.length - 1 && <br />}
					</React.Fragment>
				))}
			</>
		);
	}

	render() {
		return (
			<>
				<div className={Styles.navbar} />
				<ParallaxStarsBackground
					isHeader={this.props.starsBackgroundHeader}
					componentToRender={
						<div className={`${Styles.heroContent} px-lg-5 px-4`} onMouseEnter={() => updateCursor('white')}>
							<div className="mb-2 d-flex flex-column flex-md-row justify-content-between align-items-center px-lg-5 mx-lg-5">
								<h2 className={Styles.caseStudyHeading}>{this.props.caseStudyHeading}</h2>
								{
									this.props.link
								&& <Link
									className={Styles.visitSiteLink}
									to={this.props.link}
									target="_blank"
									rel="noopener noreferrer"
									onMouseEnter={() => {
										updateCursor('blue');
										cursorPointer(true);
									}}
									onMouseLeave={() => {
										updateCursor('white');
										cursorPointer(false);
									}}>
										Visit Site
								</Link>
								}
							</div>
							<h4 className={`${Styles.sloganHeading} font-weight-bold mb-3 px-lg-5 mx-lg-5`}>{this.props.sloganHeading}</h4>
							<p className={`${Styles.caseStudyText} lead px-lg-5 mx-lg-5`}>
								{this.renderWithLineBreaks(this.props.caseStudyText)}
							</p>
						</div>
					} />
			</>
		);
	}
}

export { CaseStudyHeroSection };
